const footerData = [
  {
    text: '',
    href: '',
    subnav: [
      { text: 'Media & Advertising', href: '/media-and-advertising' },
      { text: 'Performance Marketing', href: '/performance-marketing' },
    ],
  },
  {
    text: '',
    href: '',
    subnav: [
      { text: 'Our Properties', href: '/our-properties' },
      { text: 'Our Culture', href: '/our-culture' },
    ],
  },
  {
    text: '',
    href: '',
    subnav: [
      { text: 'Partner With Us', href: '/partner-with-us' },
      { text: 'Careers', href: '/careers' },
      { text: 'Privacy and Terms', href: '/privacy' },
    ],
  },
]

export default footerData
