import React from 'react'
import data from '../data/footerData'
import logo from '../images/clearlink-logo-blue.svg'

const footer = () => {
  const newHeaderData = {
    disclaimers: [
      {
        text: 'Clearlink Technologies 2024',
        symbol: '©',
      },
    ],
    linkGroups: data,
    // logoBackgroundColor: 'black',
    // logoTextColor: 'white',
    // navigationBackgroundColor: 'white',
    // navigationTextColor: 'black',
    // disclaimerBackgroundColor: 'white',
    // disclaimerTextColor: 'black',
    logoLink: '/',
    logo: <img src={logo} alt="Logo" />,
  }

  return newHeaderData
}

export default footer
