import React from 'react'
import data from '../data/headerData'
import logo from '../images/clearlink-logo-white.svg'

const header = () => {
  const newHeaderData = {
    logo: <img src={logo} alt="Logo" />,
    logoLink: '/',
    linkGroups: data,
    headerTextColor: 'white',
    headerBackgroundColor: 'black',
    hoverTextColor: 'white',
    hoverBackgroundColor: 'black',
    navigationTextColor: 'white',
    hideLocation: true,
  }

  return newHeaderData
}

export default header
