const headerData = [
  {
    text: 'Our Services',
    href: '/our-services',
    subnav: [
      { text: 'Sales Solutions', href: '/sales-solutions' },
      { text: 'Performance Marketing', href: '/performance-marketing' },
      { text: 'SocialX', href: '/socialx/services' },
      { text: 'Media & Advertising', href: '/media-and-advertising' },
    ],
  },
  {
    text: 'Our Properties',
    href: '/our-properties',
  },
  {
    text: 'Our Culture',
    href: '/our-culture',
  },
  {
    text: 'Partner With Us',
    href: '/partner-with-us',
  },
  {
    text: 'Contact Us',
    href: '/contact',
  },
  {
    text: 'Careers',
    href: '/careers',
  },
]

export default headerData
