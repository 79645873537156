/* eslint-disable */
import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import NarrowBanner from '../components/NarrowBanner'
import ManWithHeadset from '../images/people/man-with-headset.jpg'
import ManWithHeadsetMobile from '../images/people/man-with-headset-mobile.jpg'
import SocialXImg from '../images/people/SocialX.png'
import AutomationImg from '../images/people/automation.png'
import SalesTeamImage from '../images/people/dedicated-sales-team.png'
import seo from '../images/illustrations/seo.png'
import ppc from '../images/illustrations/ppc.png'
import createImage from '../utils/createImage'
import useWindowSize from '@leshen/ui/src/hooks/useWindowSize'

import {
  Accordion,
  Billboard,
  Column,
  Columns,
  Image,
  LinkButton,
  Stack,
  Typography,
  VariableContent,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../components/Header'
import footer from '../components/Footer'
import Footer from '../components/Footer'
import footerData from '../data/footerData'
import headerData from '../data/headerData'

const Home = () => {
  const windowSize = useWindowSize()
  const isMobile = windowSize.width < 940

  const SeoIcon = createImage(
    [seo],
    isMobile ? 214 : 326,
    isMobile ? 87 : 215,
    'fixed'
  )
  const PpcIcon = createImage(
    [ppc],
    isMobile ? 214 : 326,
    isMobile ? 87 : 215,
    'fixed'
  )

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(),
        seo: {
          title: 'Performance Marketing Company - SEO, PPC & More | Clearlink',
          description:
            'If you’re looking for a performance marketing partnership that delivers, contact the team at Clearlink today! Learn about our SEO, PPC, and other capabilities!',
          canonical: 'https://clearlink.com/',
          robots: 'follow,index',
        },
        path: '/',
        promoCode: 'SAMPLEPROMO',
        ringPool: 'RINGPOOL123',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://clearlink.com',
          siteName: 'Clearlink',
          alternateName: 'Clearlink',
          removeTrailingSlashes: true,
        },
      },
    },
    titleTemplate:
      'Performance Marketing Company - SEO, PPC & More | Clearlink',
    defaultRobots: 'follow,index',
    header: header(),
    footer: footer(),
    _main: (
      <>
        <Billboard
          backgroundColor="dark"
          alignImageToBottom
          mobileAlignImageToBottom
          variant="full"
          className="no-padding-bottom-mobile"
          image={
            <img
              src={ManWithHeadset}
              alt="a customer service representative wearing glasses and a headset"
            />
          }
          mobileImage={
            <img
              src={ManWithHeadsetMobile}
              alt="a customer service representative wearing glasses and a headset"
            />
          }
          mainContent={
            <>
              <div
                data-sal="slide-right"
                data-sal-delay="100"
                data-sal-duration="500"
                data-sal-easing="ease"
              >
                <Typography variant="h1">
                  A performance marketing partnership that delivers
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography className="mb-32">
                  Our unique business model emphasizes performance over
                  everything else, which means we are highly strategic and
                  efficient in how we operate. We’ll find the customers for you
                  and drive the metrics that will dramatically grow your
                  business.
                </Typography>
                <LinkButton
                  variant="hero"
                  to="#see-what-we-mean"
                  color="primary"
                >
                  See what we mean{' '}
                </LinkButton>
              </div>
            </>
          }
        />

        <VariableContent backgroundColor="white" id="see-what-we-mean">
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column
                image={<Image data={SeoIcon} alt="SEO LOGO ICON" />}
                className="left-aligned-image no-content-padding-left"
              >
                <Typography variant="h3">
                  Organic growth that lasts
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Increase your brand’s visibility and reputation with our
                  data-driven insights and expertise. Our search engine
                  optimization experts customize their approach to your unique
                  business needs and consider every opportunity to cultivate
                  sustainable growth.
                </Typography>
              </Column>
              <Column
                image={<Image data={PpcIcon} alt="PPC LOGO ICON" />}
                className="left-aligned-image no-content-padding-left"
              >
                <Typography variant="h3">
                  Clicks that convert
                  <span className="blue-punctuation-large">.</span>
                </Typography>
                <Typography variant="body">
                  Our paid media team proactively creates and manages the
                  campaigns that get results—keeping costs low and conversions
                  high. We’ll coordinate with you to find the most efficient
                  ways to secure new customers that last.
                </Typography>
              </Column>
            </Columns>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Typography variant="h2" className="text-center">
              More of what makes us tick
              <span className="blue-punctuation-large">.</span>
            </Typography>
          </div>
          <div
            data-sal="slide-up"
            data-sal-duration="500"
            data-sal-easing="ease"
          >
            <Columns leftAligned={true}>
              <Column
                image={<img src={SalesTeamImage} alt="Dedicated Sales Team" />}
              >
                <Typography variant="h4">
                  Dedicated Sales Team: Close the deal
                </Typography>
                <Typography variant="body">
                  Seamlessly integrating our marketing and internal sales teams
                  means greater insight into customer behavior and greater
                  control over the user experience—allowing us to track and
                  optimize conversions online, via chat, or over the phone.
                </Typography>
                <LinkButton to="/partner-with-us" color="primary">
                  Start selling with us
                </LinkButton>
              </Column>
              <Column
                image={
                  <img
                    src={AutomationImg}
                    alt="Automation: Marketing on AutoPilot"
                  />
                }
              >
                <Typography variant="h4">
                  Automation: Marketing on autopilot
                </Typography>
                <Typography variant="body">
                  From personalized email campaigns to dynamic remarketing
                  efforts that reengage potential customers, our automated
                  marketing services ensure that every lead is maximized and no
                  users fall through the cracks.
                </Typography>
                <LinkButton to="/partner-with-us" color="primary">
                  Start automating
                </LinkButton>
              </Column>
              <Column image={<img src={SocialXImg} alt="SocialX" />}>
                <Typography variant="h4">
                  SocialX: Give them something to talk about
                </Typography>
                <Typography variant="body">
                  Our SocialX team does the work for you, monitoring your online
                  reputation and engaging with repeat customers and new buyers
                  alike to increase awareness and positive brand sentiment. We
                  offer a suite of services designed to boost your standing in
                  the social space.
                </Typography>
                <LinkButton to="/socialx/services" color="primary">
                  See how it's done
                </LinkButton>
              </Column>
            </Columns>
          </div>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <>
              <Typography className="s3" variant="h4">
                Frequently Asked Questions
                <span className="blue-punctuation-large">.</span>
              </Typography>
              <br />
              <Stack spacing="xl">
                <Accordion
                  items={[
                    {
                      content: (
                        <Typography variant="body">
                          Just like the name implies, a performance marketing
                          agency does more than basic contract work. We
                          structure our contracts to encourage results—with
                          conversion being the most important statistic.
                          Performance-based marketing is designed to seek out
                          opportunities and maximize marketing efforts. When you
                          work with a performance marketing agency, you can
                          expect to get our full attention and creativity
                          because we only get paid when we succeed. We are hyper
                          focused on your return on investment, which means that
                          we’re constantly iterating and cutting efforts that
                          aren’t performing. You set your goals and initiatives
                          and we’ll find the best ways to implement the strategy
                          and tactics to realize your desired result. With
                          performance-based marketing, your success is our
                          success.
                        </Typography>
                      ),
                      title: (
                        <Typography>
                          <span className="size-20-bold" variant="h4">
                            What is a performance marketing agency?
                          </span>
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography variant="body">
                          Performance marketing agencies aren’t too dissimilar
                          from traditional agencies. We have experienced staff
                          proficient in search engine optimization, paid media,
                          content creation, design, and more. The difference is
                          how we take ownership to find additional opportunities
                          to grow beyond the bare minimum. We meet regularly
                          with brand representatives to ensure we are
                          representing your company and services well while
                          constantly pushing for better results. Our goal is to
                          be in lockstep with your organization so that we can
                          deliver beyond your expectations.
                        </Typography>
                      ),
                      title: (
                        <Typography>
                          <span className="size-20-bold" variant="h4">
                            How does a performance marketing agency work?
                          </span>
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography variant="body">
                          The basis for performance marketing is incentive. We
                          want to hit certain markers and benchmarks so that
                          we’re growing your brand recognition and customer
                          base. For example, if you have a new product coming
                          out, we’ll create a PPC ad campaign with landing pages
                          as well as design product pages to boost SEO—all while
                          driving leads to our sales center to close the deal.
                          It’s our responsibility to make the sale and we’ll
                          only get paid for the customers we create. You give us
                          the information and the guidelines and we’ll run with
                          the rest. We have decades of experience converting new
                          customers for some the biggest brands. We thrive in
                          this performance-based model and we know that your
                          business will too.
                        </Typography>
                      ),
                      title: (
                        <Typography>
                          <span className="size-20-bold" variant="h4">
                            What is an example of performance marketing?
                          </span>
                        </Typography>
                      ),
                    },
                    {
                      content: (
                        <Typography variant="body">
                          Costs can vary and it really depends on what you’re
                          looking for! We employ a full-funnel approach to
                          performance-based marketing and can offer you anything
                          from social media services to SEO to digital PR and
                          more. We even have our very own award-winning sales
                          center that is expert at closing the deal when your
                          customer reaches the end of their buying journey. We
                          take great pride in our services and are confident
                          that whatever your business needs, we can create a
                          customized service solution to fit your goals and
                          budget.
                        </Typography>
                      ),
                      title: (
                        <Typography>
                          <span className="size-20-bold" variant="h4">
                            How much does a performance marketing agency cost?
                          </span>
                        </Typography>
                      ),
                    },
                  ]}
                />
              </Stack>
            </>
          }
        />
        <NarrowBanner
          backgroundColor="#000000"
          heading={
            <div
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              <Typography variant="h5" className="s2">
                Let's grow together
              </Typography>
            </div>
          }
          button={
            <LinkButton
              to="/partner-with-us"
              color="primary"
              data-sal="slide-left"
              data-sal-delay="300"
              data-sal-duration="500"
              data-sal-easing="ease"
            >
              Partner with us
            </LinkButton>
          }
        />
      </>
    ),
    get main() {
      return this._main
    },
    set main(value) {
      this._main = value
    },
  }

  return <Layout {...layoutProps} />
}
export default Home
